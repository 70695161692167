.ps-table-no-border {
	text-align: start;
	max-width: 100%;
	width: 100%;
	border: none;
	background-color: transparent;
}

.ps-table-no-border-yooma {
	text-align: start;
	max-width: 100%;
	width: 100%;
	border: none;
	background-color: transparent;
}

.ps-table-no-border-yooma thead th {
	background-color: var(--fg-c3);
	color: var(--text-c4);
	padding: 0.75rem;
	font-weight: bold;
}

.ps-table-no-border-yooma tbody tr:hover {
	background-color: var(--fg-c5);
	color: var(--text-c4);
}

.ps-table-no-border-yooma tbody td {
	padding: 0.75rem;
}


