.ps-progress-container {
	width: 100%;
	background-color: var(--bg-c3);
	border-radius: 25px;
	overflow: hidden;
	margin: 2em 0;
}

.ps-progressbar-group {
	display: flex;
}

.ps-progressbar-group div:last-child {
	border-radius: 0px;
}

.ps-progressbar-group div:first-child {
	border-radius: 25px 0 0 25px;
}

[class^='ps-progressbar-'], [class*=' ps-progressbar-'] {
	height: 2em;
	width: 60%; /* Change this to set the progress */
	/*border-radius: 25px 0 0 25px;*/
}

.ps-progressbar-success {
	background-color: var(--bg-success);
}

.ps-progressbar-primary {
	background-color: var(--bg-primary);
}

.ps-progressbar-warning {
	background-color: var(--bg-warning);
}

.ps-progressbar-danger {
	background-color: var(--bg-danger);
}
